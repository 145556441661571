<template>
  <div class="about section-vh">
    <b-container>
      <b-row>
        <b-col class="col my-5">
          <div class="text-left text-white" v-if="$i18n.locale === 'ru'">
            <div class="h5 text-left rl-text-color border_title pb-4 mb-4">
              Система проведения соревнований – регулярные турниры Head-2-Head
              Liga по Rocket League.
            </div>
            <p>
              <b class="text-left rl-text-color"
                >Продолжительность турнира составляет не более одного дня.</b
              >
            </p>
            <p>
              Турнир состоит из 18 матчей: 18 матчей группового этапа, круговая
              система (sextuple round robin) в шесть кругов. Если матч в
              основное время закончился ничейным результатом, то игра
              переводится в овертайм. Количество участников турнира – 3 команды.
            </p>
            <p>
              <b class="text-left rl-text-color"
                >Турнир проводится в один этап:</b
              ><br />
            </p>
            <p>
              Групповой этап. Матчи группового этапа проводятся до выявления
              победителя, с овертаймом, если в основное время встреча
              закончилась вничью. Овертайм играется до первого забитого гола.
            </p>
            <p>
              Все команды играют в шесть кругов между собой (у каждой из команд
              по 12 матчей), всего 18 матчей в группе. Победитель выявляется на
              основании лучших показателей – набранных очков.
            </p>
            <p>
              В ходе проведения группового этапа турнира команды набирают
              игровые очки, которые по итогам каждого матча турнира
              складываются. Итоговое место команды в групповом этапе
              определяется по сумме набранных очков. В зависимости от результата
              матча командам начисляются очки:
            </p>
            <p>
              - за победу в матче – 1 очко;<br />
              - за поражение в матче – 0 очков.
            </p>
            <p>
              <b class="text-left rl-text-color"
                >В случае если одинаковое количество очков было набрано двумя
                командами, победитель определяется согласно правилам, указанным
                ниже:</b
              >
            </p>
            <p>
              - по наибольшему количеству побед;<br />
              - по результату личной встречи;<br />
              - по разнице забитых и пропущенных мячей в матчах между собой;<br />
              - по количеству забитых мячей в матчах между собой.
            </p>
            <p>
              <b class="text-left rl-text-color"
                >В случае если одинаковое количество очков было набрано тремя и
                более командами, победитель определяется согласно правилам,
                указанным ниже (по ранжированию):</b
              >
            </p>
            <p>
              - по наибольшему количеству побед;<br />
              - по результату личной встречи;<br />
              - по разнице забитых и пропущенных мячей в матчах между собой;<br />
              - по большему количеству забитых мячей в матчах между собой.
            </p>
            <p>
              По окончании группового этапа производится подсчет очков, по
              итогам которого выявляется победитель турнира.
            </p>
          </div>

          <div class="text-left text-white" v-else>
            <div class="h5 text-left rl-text-color border_title pb-4 mb-4">
              Competition system: regular Head-2-Head Liga tournaments in Rocket
              League.
            </div>
            <p>
              <b class="text-left rl-text-color"
                >Tournament duration is no longer than one day.</b
              >
            </p>
            <p>
              The tournament consists of 18 matches: 18 group stage matches and
              a sextuple round robin. If a match ends in a draw in regular time,
              the game will go into overtime. Number of tournament participants:
              3 teams.
            </p>
            <p>
              <b class="text-left rl-text-color"
                >The tournament is held in one stage:</b
              ><br />
            </p>
            <p>
              Group stage. Group stage matches are held until a winner is
              determined, with an overtime if regular match time ends in a draw.
              Overtime lasts until the first goal is scored.
            </p>
            <p>
              All teams play six rounds between themselves (each team has 12
              matches), with a total of 18 matches in the group. The winner is
              determined on the basis of the number of points scored.
            </p>
            <p>
              During the group stage of the tournament, the teams earn game
              points that are added up at the end of each tournament match. The
              final place of the team in the group stage is determined by the
              sum of points earned. Teams earn points depending on the match
              result:
            </p>
            <p>
              - for a match win — 1 point;<br />
              - for a match loss — 0 points.
            </p>
            <p>
              <b class="text-left rl-text-color"
                >In the event that the same number of points has been earned by
                two teams, the winner is determined based on the rules indicated
                below:</b
              >
            </p>
            <p>
              - by the most wins;<br />
              - by H2H results;<br />
              - by the difference between goals scored and conceded in matches
              between themselves;<br />
              - by the number of goals scored in matches between themselves.
            </p>
            <p>
              <b class="text-left rl-text-color"
                >In the event that the same number of points has been earned by
                three or more teams, the winner is determined based on the rules
                indicated below (by ranking):</b
              >
            </p>
            <p>
              - by the most wins;<br />
              - by H2H results;<br />
              - by the difference between goals scored and conceded in matches
              between themselves;<br />
              - by the larger number of goals scored in matches between
              themselves.
            </p>
            <p>
              At the end of the group stage, the points are calculated and the
              winner of the tournament is announced.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "rocket_league_rules",
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: this.$t("meta.sports.rl.rules.title"),
      titleTemplate: "H2H Liga | %s",
      meta: [
        {
          name: "description",
          content: this.$t("meta.sports.rl.rules.description")
        }
      ]
    };
  }
};
</script>

<style scoped>
.border_title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
</style>
